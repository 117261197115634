.App {
  text-align: center;
}

body {
  /* background: #d2e5f4 !important; */
}

#teach-tabs div[role="tablist"] button {
  border: none !important;
  box-shadow: 1px 1px 2px 0 #afafaf !important;
  border-radius: 12px;
}

#teach-tabs div[role="tablist"] {
  border: none !important;
}

#teach-tabs button[aria-selected="true"] {
  background: #d2e5f4;
  /* color: #000; */
}

#teach-tabs button[aria-selected="false"] {
  background: #fff;
  color: #3182ce;
}

.h-stud {
  height: calc(100vh - 13.75rem);
}

.h-stud-ad {
  height: calc(100vh - 9rem);
}

.icon.icon-tabler.icon-tabler-calendar-event {
  right: 8px !important;
}

.h-teach-l {
  height: calc(100vh - 14rem);
}

.chakra-collapse[style*="height: auto"]+.h-teach-l {
  height: calc(100vh - 17.5rem);
}

.h-teach-l1 {
  height: calc(100vh - 10rem);
}

.chakra-collapse[style*="height: auto"]+.h-teach-l1 {
  height: calc(100vh - 13.5rem);
}

.h-teach-l2 {
  height: calc(100vh - 13.125rem);
}

.chakra-collapse[style*="height: auto"]+.flex+.h-teach-l2 {
  height: calc(100vh - 16.625rem);
}

.h-teach-r {
  height: calc(100vh - 4rem);
}

.chakra-card {
  box-shadow: 2px 2px 5px 0 #afafaf !important;
}

.chakra-switch__track {
  border-radius: 8px !important;
}

.switch-txt>span>span {
  background: transparent !important;
}

/* ProcessPage Switch */
.sw-proc .css-7roig {
  background: var(--chakra-colors-white) !important;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.sw-proc .css-1qdc3ms {
  border-radius: 50px !important;
  height: 20px;
}

.switch-txt>span {
  background: #fff !important;
  border: 1px solid transparent;
  width: auto !important;
  display: flex !important;
  flex-grow: 1;
  /* min-width: 5em; */
}

.css-1igwmid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.\!mt-2 .css-1igwmid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}
/* 
@media (min-width: 767px) {
  .css-1igwmid {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
} */

.switch-txt {
  /* white-space: nowrap; */
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  line-height: 0.75rem !important;
  display: flex !important;
  align-items: center;
}

.session .css-4v7wu5 {
  /* min-width: 4em; */
}

.switch-txt>span[aria-checked=true], .switch-txt>span[data-checked] {
  background: #1da1f2 !important;
  color: #000;
}

.switch-txt:before {
  display: block;
  color: #000;
  content: attr(content);
  position: absolute;
  /* top: 0.875rem; */
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.75rem;
}

.switch-txt[data-checked][data-disabled]:before {
  color: #000;
}

.switch-txt[data-checked]:before {
  color: #fff;
}

input[type=file]::file-selector-button {
  /* margin-right: 20px; */
  border: none;
  background: #fff;
  /* padding: 10px 20px; */
  /* border-radius: 10px; */
  color: #a3a3a3;
  /* cursor: pointer; */
  /* transition: background .2s ease-in-out; */
}

.css-1y0e7gb {
  isolation: auto !important;
}

.css-pv22qu, .css-1xdvviv {
  border-radius: 16px !important;
}

.css-9fgtzh {
  font-size: 16px !important;
  text-align: center;
}

/* 申請中bg */
.bg-ing {
  background: #bcd5ac !important;
}

/* 已完成bg */
.bg-ed {
  background: #9bbee0 !important;
}

/* 已逾期bg */
.bg-over {
  background: #ababab !important;
}

.bg-all {
  background: #f7c09c !important;
}

/* 退回bg */
.bg-return {
  background: #ffa3a3 !important;
}
/* 同步失敗bg */
.bg-failed {
  background: #ffa3c0 !important;
}
.bg-ing.active {
  background: #5cc11d !important;
}

.bg-ed.active {
  background: #1882e2 !important;
}

.bg-all.active {
  background: #f37928 !important;
}

.bg-return.active {
  background: #ff4a4a !important;
}
.bg-failed.active {
  background: #ff4aa4 !important;
}
button.active {
  box-shadow: 0px 0px 3px 2px #a5a5a5;
}

/* 日曆 */
.rmdp-week .rmdp-week-day:first-child, .rmdp-week .rmdp-week-day:last-child {
  color: #000 !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #FF5722 !important;
}

.rmdp-day.rmdp-today span {
  background-color: #ffb0bf !important;
}

.rmdp-range {
  background-color: #ff5a26 !important;
}

@media (hover: hover) {
  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: #ffa386 !important;
  }
}

.rmdp-week-day {
  color: #FF5722 !important;
}

.rmdp-arrow {
  border-color: #ff5a26 !important;
  height: 8px !important;
  width: 8px !important;
  margin-top: 6px !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border-color: #fff !important;
}

.rmdp-arrow-container:hover {
  background-color: #ff5a26 !important;
}

.rmdp-input {
  height: 38px !important;
  width: 100%;
  padding-right: 36px !important;
  padding-left: 16px !important;
}

.rmdp-container {
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc98;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* background-color: #e9e9e9; */
}

#viewer #toolbar {
  display: none !important;
}

.css-x9dxnn {
  max-width: 700px !important;
}

.grid-self {
  grid-template-columns: 1fr;
}

@media(min-width: 1024px) {
  .grid-self {
    grid-template-columns: 1fr 2px 1fr;
  }
}

.imgs {
  cursor: url(../public/assets/img/zoom-in.svg), auto;
}

.imgPreview {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1049;
}

.imgPreview img {
  z-index: 1050;
  width: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
}

.signbg {
  background: no-repeat center/100% url(../public/assets/img/signinbg.png);
}

.signbgEn {
  background: no-repeat center/100% url(../public/assets/img/signinbgEn.png);
}

.myanim.w0 {
  animation: myanimr 0s;
  -webkit-animation: myanimr 0s;
  animation-fill-mode: backwards;
}

@media (max-width: 767px) {
  .myanim.w0 {
    animation: myanimr .5s;
    -webkit-animation: myanimr .5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }

  .myanim {
    animation: myanim .5s;
    -webkit-animation: myanim .5s;
    animation-timing-function: ease;
  }

}

@keyframes myanim {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@-webkit-keyframes myanim {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes myanimr {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}

@-webkit-keyframes myanimr {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}

/* @-webkit-keyframes myanimr {
  from {
    width: 100%;
  }

  to {
    width: 0px;
  }
} */

a.active li, li.actived {
  font-weight: 600;
  background: #d6f5f080 !important;
  cursor: default;
}

.chakra-accordion__item .css-1577qb8 {
  vertical-align: middle !important;
  /* background: #fff; */
  border-color: #00b4d8a5;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0;
}

.react-datepicker__input-container input {
  padding: 6px 24px 5px 12px !important;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #FF5722 !important;
  border-radius: 50px !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #ffb0bf !important;
  border-radius: 50px !important;
}

:focus-visible {
  outline: 0 !important;
  border-color: #ffb0bf !important;
}

/* input, select, textarea : dark : focus-visible */
.css-10dip6r:focus-visible, .css-10dip6r[data-focus-visible],
.css-161pkch:focus-visible, .css-161pkch[data-focus-visible],
.css-13tb85k:focus-visible, .css-13tb85k[data-focus-visible],
.css-1msuebi:focus-visible, .css-1msuebi[data-focus-visible],
.css-1wq9qmd:focus-visible, .css-1wq9qmd[data-focus-visible] {
  box-shadow: none !important;
}

.chakra-input__group .react-datepicker__header,
.react-datepicker__month-container .react-datepicker__header {
  background-color: #fff;
  border: 0;
}

.chakra-input__group .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #fff;
  color: #fff;
}

.react-datepicker {
  box-shadow: 0 0 5px #8798ad;
  border: 0 !important;
}

.react-datepicker__day-name {
  color: #FF5722 !important;
}

.react-datepicker__day-name:last-child,
.react-datepicker__day-name:first-child {
  color: #000000 !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ff5722 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  padding: 8px 0;
}

.react-datepicker__navigation {
  padding-top: 16px !important;
}

.chakra-accordion__item .css-mzllf6 {
  background: #f0f0f052;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0 !important;
  height: 8px !important;
  width: 8px !important;
}

td .css-1q5h6dd, td .css-49ak1w {
  border-color: #00b4d8a5;
  margin-top: 4px;
}

.css-dgi4x4, .css-z27lgt {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.timeTb td, .timeTb th {
  text-align: center;
}

.procTb td, .procTb th {
  text-align: center;
  padding: 12px 16px;
}

.procTb .chakra-button__icon.css-1hzyiq5 {
  margin-left: auto !important;
  /* right: 8px;
  position: absolute; */
}

.procTb tr:nth-of-type(even) .css-dgi4x4 {
  background: var(--chakra-colors-gray-100);
}

.procTb tr:nth-of-type(even) .css-z27lgt {
  background: var(--chakra-colors-gray-700);
}

.css-1cjy4zv:focus-visible, .css-1cjy4zv[data-focus-visible],
.css-1ndqqtl:focus-visible, .css-1ndqqtl[data-focus-visible],
.css-go9bu5:focus-visible, .css-go9bu5[data-focus-visible],
.css-16kavka:focus-visible, .css-16kavka[data-focus-visible],
.css-h9ln2y:focus-visible, .css-h9ln2y[data-focus-visible] {
  box-shadow: none !important;
}

.chakra-ui-dark .css-1qdc3ms[aria-checked=true]:not([data-theme]), .chakra-ui-dark .css-1qdc3ms[data-checked]:not([data-theme]), [data-theme=dark] .css-1qdc3ms[aria-checked=true]:not([data-theme]), [data-theme=dark] .css-1qdc3ms[data-checked]:not([data-theme]), .css-1qdc3ms[aria-checked=true][data-theme=dark], .css-1qdc3ms[data-checked][data-theme=dark] {
  --switch-bg: var(--chakra-colors-cyan-400) !important;
}

.css-gv1iwz {
  z-index: 0 !important;
}

.css-ktmu3v:focus-visible, .css-ktmu3v[data-focus-visible] {
  box-shadow: none !important;
}

.css-lge5hr {
  margin-left: 16px;
  margin-right: 16px;
}

.custom-modal-content {
  width: auto !important;
}

.tools {
  position: fixed;
  /* or 'absolute', depending on layout */
  top: 20px;
  left: 20px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* Space between buttons */
  background-color: #fff;
  /* Background color of the tools container */
  padding: 10px;
  border-radius: 5px;
  /* Rounded corners for the tools container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}

.tools button {
  cursor: pointer;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  /* Button background color */
  color: #ffffff;
  /* Button text color */
  padding: 5px 10px;
  border-radius: 5px;
  /* Rounded corners for buttons */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
}

.tools button:hover {
  background-color: #0056b3;
  /* Darker shade for hover effect */
}

.css-pmiw7h {
  background: #1DA1F2;
}
.css-1ac5iot {
  background: #1DA1F2;
}
.css-6bvm0t[aria-checked=true], .css-6bvm0t[data-checked], .css-6bvm0t[data-state=checked]  {
  background: #1DA1F2 !important;
  border-color: #1DA1F2 !important;
  color: var(--chakra-colors-white)!important;
}